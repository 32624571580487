<template>
  <v-container
    style="max-width: 500px"
    class="pt-0"
    grid-list-md
    v-if="statusMovieDetails === 'success'"
  >
    <v-breadcrumbs
      class="px-0"
      :items="[
        {
          text: $t('vod.browse'),
          disabled: false,
          to: getRoute('browse'),
        },
        {
          text: movieDetails.title,
          disabled: true,
        },
      ]"
    >
    </v-breadcrumbs>
    <v-card outlined>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat>
            <v-container fluid class="pa-1 ma-0">
              <v-layout
                justify-center
                v-if="movieDetails.fanart || movieDetails.cover"
              >
                <v-img
                  :aspect-ratio="2 / 3"
                  :src="
                    $config.managerMedia +
                    (movieDetails.fanart
                      ? movieDetails.fanart
                      : movieDetails.cover)
                  "
                  :lazy-src="
                    $config.managerMedia +
                    (movieDetails.fanart
                      ? movieDetails.fanart
                      : movieDetails.cover)
                  "
                ></v-img>
              </v-layout>
            </v-container>
            <v-card-title style="word-break: break-word">{{
              movieDetails.title
            }}</v-card-title>
            <v-container>
              <v-btn
                :loading="
                  statusResumeTime === 'fetching' ||
                  statusMovieDetails === 'fetching'
                "
                fab
                absolute
                color="primary"
                @click="playVideo(movieDetails.app_stream)"
                v-if="movieDetails.is_free || movieDetails.already_bought"
                right
                bottom
              >
                <v-icon>mdi-play</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                block
                v-else-if="
                  !movieDetails.already_bought && movieDetails.price > 0
                "
                @click="buyMovie()"
              >
                {{
                  (movieDetails.price / 100)
                    | currency($store.state.session.currencyConfig)
                }}
              </v-btn>
              <v-btn
                color="primary"
                block
                class="mt-2"
                v-else-if="
                  !movieDetails.already_bought && movieDetails.in_package
                "
                :to="getRoute(movieDetails)"
                >{{ $t("vod.detailBuy.inPackage") }}</v-btn
              >
              <v-btn
                color="primary"
                block
                class="mt-2"
                outlined
                text
                v-if="
                  vouchers.limit_non_adult > vouchers.non_adult_count &&
                  movieDetails.price > 0 &&
                  !movieDetails.already_bought
                "
                @click="useVoucher = !useVoucher"
              >
                <v-icon>mdi-gift</v-icon>
              </v-btn>
            </v-container>
          </v-card>
          <v-divider></v-divider>
        </v-flex>

        <v-flex xs12>
          <v-container>
            <template
              v-if="
                movieDetails.description && movieDetails.description.length > 0
              "
            >
              <h3>{{ $t("vod.description") }}</h3>
              <div class="text-subtitle-1">{{ movieDetails.description }}</div>
            </template>
          </v-container>
        </v-flex>

        <v-flex xs12>
          <v-container class="py-0">
            <v-list v-if="movieDetails.app_trailer_stream">
              <v-list-item @click="playVideo(movieDetails.app_trailer_stream)">
                <v-list-item-avatar>
                  <v-icon>mdi-filmstrip</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>{{
                  $t("vod.actions.buyTrailer")
                }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
          <v-divider></v-divider>
        </v-flex>

        <v-flex
          xs12
          v-if="movieDetails.actors && movieDetails.actors.length > 0"
        >
          <v-container class="py-0">
            <h3>{{ $t("vod.actors") }}</h3>
            <v-simple-table>
              <tbody>
                <tr v-for="item in movieDetails.actors" :key="item.name">
                  <td class="pl-0 py-3">{{ item.name }}</td>
                  <td class="pr-0 py-3">{{ item.role }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-flex>
      </v-layout>
    </v-card>
    <buy-dialog></buy-dialog>
    <v-dialog v-model="useVoucher">
      <v-card>
        <v-card-title style="word-break: break-word">{{
          $t("vod.detailBuy.useNonAdultVoucherDesc", {
            title: movieDetails.title ? movieDetails.title : "",
            num: vouchers.limit_non_adult,
          })
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="useVoucher = !useVoucher">{{
            $t("vod.detailBuy.no")
          }}</v-btn>
          <v-btn
            text
            color="primary"
            @click="
              (useVoucher = !useVoucher),
                buyMovieOrPackage({
                  movie_id: movieDetails.item_id.toString(),
                  type: 'voucher',
                })
            "
            >{{ $t("vod.detailBuy.yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import BuyDialog from "@/components/vod/BuyDialog";

export default {
  data() {
    return {
      useVoucher: false,
    };
  },
  components: {
    BuyDialog,
  },
  computed: {
    ...mapState({
      statusResumeTime: ({ vod }) => vod.statusResumeTime,
      authResult: ({ session }) => session.authResult,
      movieDetails: ({ vod }) => vod.movieDetails,
      statusBuyProcess: ({ vod }) => vod.statusBuyProcess,
      statusMovieDetails: ({ vod }) => vod.statusMovieDetails,
      status: ({ appplayer }) => appplayer.status,
      resumeTime: ({ appplayer }) => appplayer.resumeTime,
      vouchers: ({ vod }) => vod.vouchers,
      statusVouchers: ({ vod }) => vod.statusVouchers,
    }),
    loading() {
      return (
        this.statusVouchers === "fetching" ||
        this.statusMovieDetails === "fetching"
      );
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_LOADING",
    }),
    ...mapActions("vod", [
      "getMovieDetails",
      "addResumeTime",
      "getVouchers",
      "buyMovieOrPackage",
      "setBuyDialog",
    ]),
    ...mapActions("buydialog", ["setDialogData"]),
    ...mapActions("appplayer", [
      "setVideoUrl",
      "setPlayState",
      "setResumeTime",
    ]),
    initData() {
      this.getMovieDetails({
        view: "detail",
        item_id: this.$route.params.movieId,
      });
      this.getVouchers({});
    },
    buyMovie() {
      this.setBuyDialog({ state: true });
      if (this.movieDetails.price > 0) {
        this.setDialogData({ item: this.movieDetails });
      }
    },
    playVideo(stream) {
      const videoUrl = this.$config.app_vod_path + stream;
      this.setVideoUrl({ url: videoUrl });
      this.setPlayState({ state: "play" });
      this.setResumeTime({ time: this.movieDetails.resume_time });
    },
    getRoute(details) {
      if (details === "browse") {
        return {
          name: "browse",
        };
      }

      if (details.in_package) {
        return {
          name: "packagesByMovie",
          params: {
            movieId: details.item_id,
          },
        };
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.status === "play") {
      this.setPlayState({ state: "stopped" });
      next(this.$route.path);
    } else {
      next();
    }
  },
  watch: {
    resumeTime(val) {
      const millis = Math.round(val * 1000);
      this.addResumeTime({
        timeInMillis: millis,
        item_id: this.movieDetails.item_id.toString(),
      });
    },
    loading(val) {
      this.setLoading(val);
    },
    statusBuyProcess(val) {
      if (val === "success") {
        this.setBuyDialog({ state: false });
        this.initData();
      }
    },
    statusResumeTime(val) {
      if (val !== "fetching") {
        this.initData();
      }
    },
  },
};
</script>
